import type { ShippingFormViewModel } from "@/types/models/order/shipping-form-view-model";
import type { ShippingOptionFormViewModel } from "@/types/models/order/shipping-option-form-view-model";
import type { OrderAddressModel } from "@/types/models/order/order-addres-model";
import type { OrderConfirmationFormViewModel } from "@/types/models/order/order-confirmation-form-view-model";
import type { OrderPaymentFormViewModel } from "@/types/models/order/order-payment-form-view-model";
import type { CustomerFormViewModel } from "@/types/models/customer/customer-form-view-model";
import type { StripeChargeResponse } from "@/types/models/stripe/stripe-charge-response";
import type { StripeDataModel } from "@/types/models/stripe/stripe-data-model";
import type { OrderViewModel } from "@/types/models/order/order-view-model";
import type { OrderInitFormViewModel } from "@/types/models/order/order-init-form-view-model";
import type { SelectListOption } from "@/types/models/select-list-item";
import type { OrderAddressEditFormViewModel } from "@/types/models/address/order-address-edit-form-view-model";
import type { OrderSaveViewModel } from "@/types/models/order/order-save-quantity-view-model";
import type { BlobUploadViewModel } from "@/types/models/order/blob-upload-view-model";
import type { DeleteCartItemViewModel, EditCartViewModel } from "@/types/models/cart/cart-item-view-model";
import type { OrderProfileViewModel } from "@/types/models/order/order-profile-view-model";
import type { SaveAdditionalInfoViewModel } from "@/types/models/order/save-additional-info-view-model";
import BaseService from './base-service';

class OrderService extends BaseService {
    async createOrder(data: OrderInitFormViewModel) {
        await this.post('/order/createOrder', data);
    }

    async saveOrderBlobUrl(model: BlobUploadViewModel) {
        await this.post('/order/saveOrderBlobUrl', model);
    }

    async syncShoppingCartItems() {
        await this.post('/order/syncShoppingCartItems');
    }

    async getSecureCustomer() {
        return await this.get('/order/getSecureCustomer');
    }

    async resetOrderBlobUrl() {
        await this.post('/order/resetOrderBlobUrl');
    }

    async getSecureOrders() {
        return await this.get<OrderProfileViewModel[]>('/order/getSecureOrders');
    }

    async getOrder() {
        return await this.get<OrderViewModel>('/order/getCurrentOrder');
    }

    async getSecureOrder(id: number) {
        return await this.get<OrderViewModel>('/order/getSecureOrder', { params: { id } });
    }

    async createCountryData() {
        let countries: SelectListOption[];
        countries = await this.get<SelectListOption[]>('/order/getCountries');
        countries.sort((x, y) => { return x.text === 'Canada' ? -1 : y.text === 'Canada' ? 1 : 0; });
        countries.sort((x, y) => { return x.text === 'United States' ? -1 : y.text === 'United States' ? 1 : 0; });
        // @ts-ignore
        countries.splice(2, 0, { text: 'Other Countries', value: '', disabled: true });

        return countries;
    }

    async createStateData(id: number) {
        return await this.get('/order/getCountryStates', { params: { id } });
    }

    async editProductInOrderAndCart(model: EditCartViewModel) {
        await this.post('/order/editProductInOrderAndCart', model);
    }

    async removeProductFromOrderAndCart(model: DeleteCartItemViewModel) {
        await this.post('/order/removeProductFromOrderAndCart', model);
    }

    async addCustomerToOrder(data: CustomerFormViewModel) {
        await this.post('/order/addCustomerToOrder', data);
    }

    async getCustomer() {
        return await this.get<CustomerFormViewModel>('/order/getCustomer');
    }

    async getCustomerCompanyName(customerId: number) {
        return await this.get<string>('/order/getCustomerCompanyName', { params: { customerId } });
    }

    async addStepToOrder(stepId: number) {
        await this.post('/order/AddStepToOrder/' + stepId);
    }

    async addAddressToOrder(data: OrderAddressModel) {
        await this.post('/order/addAddressToOrder', data);
    }

    async createShippingData(data: ShippingFormViewModel) {
        await this.post('/order/createShippingData', data);
    }

    async getShippingOptions(isStore = false, freezeProtection = false, liftgate = false) {
        return await this.get('/order/getShippingOptions', { params: { isStore: isStore, freezeProtection: freezeProtection, liftgate: liftgate } });
    }

    async estimateShipping(model: ShippingFormViewModel) {
        return await this.post('/order/estimateShipping', model);
    }

    async addShippingOptionToOrder(data: ShippingOptionFormViewModel) {
        await this.post('/order/addShippingOptionToOrder', data);
    }

    async getStripePaymentData() {
        return await this.get<StripeDataModel>('/order/getStripePaymentData');
    }

    async chargeUsingStripe(token: string) {
        return await this.get<StripeChargeResponse>('/order/chargeUsingStripe', { params: { token } });
    }

    async saveOrderPayment(data: OrderPaymentFormViewModel) {
        await this.post('/order/saveOrderPayment', data);

    }

    async getOrderSalesTaxRate() {
        return await this.get<number>('/order/getOrderSalesTaxRate');
    }

    async stateIsTaxable(stateId: number) {
        return await this.get<boolean>('/order/stateIsTaxable', { params: { stateId } });

    }

    async confirmOrder(data: OrderConfirmationFormViewModel) {
        await this.post('/order/confirmOrder', data);
    }

    async saveOrUpdateOrder(isAdminPanel = true, orderNote = '') {
        return await this.post('/order/saveOrUpdateOrder', { isAdminPanel: isAdminPanel, note: orderNote } as OrderSaveViewModel);
    }

    async EditOrderAddress(data: OrderAddressEditFormViewModel) {
        return await this.post('/order/editOrderAddress', data);
    }

    async addShipFromAddressToOrder(warehouseAddressId: number) {
        return await this.get('/order/addShipFromAddressToOrder', { params: { warehouseAddressId } });
    }

    async saveAdditionalInfo(data: SaveAdditionalInfoViewModel) {
        await this.post('/order/saveAdditionalInfo', data);
    }

    async getLatestOrderId(customerId: number, billingEmail: string) {
        return await this.get<number | null>('/order/getLatestOrderId', { params: { customerId, billingEmail } });
    }
}

export default new OrderService();